import React from "react";
import Link from "next/link";
import { Flex, VStack, Link as ChakraLink } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useAuth } from "src/hooks/api";

const DropdownBox = ({ links }) => {
  const MotionFlex = motion(Flex);
  const { logout } = useAuth();

  return (
    <MotionFlex
      pos="absolute"
      w="100%"
      maxW="680px"
      left={0}
      right={0}
      top={7}
      zIndex="1"
      margin="auto"
      justify="center"
      align="center"
      p={8}
      bg="baseDark"
      // shadow="inset rgb(0 0 0 / 50%) 0 25px 25px"
      initial={{ opacity: 1, y: -150 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: -150 }}
      transition={{ duration: 0.25 }}
    >
      <VStack spacing={2}>
        {links?.length > 0 &&
          links.map((link, index) => (
            <Link
              href={link.href}
              locale={link.locale}
              passHref
              key={`dropdown-settings-link-${index}`}
            >
              <ChakraLink
                fontWeight="semibold"
                fontSize="lg"
                textTransform="uppercase"
                color="baseLight"
                _hover={{ color: "white", textDecoration: "none" }}
                onClick={link.id === "logout" ? logout : null}
              >
                {link.title}
              </ChakraLink>
            </Link>
          ))}
      </VStack>
    </MotionFlex>
  );
};

export default DropdownBox;
