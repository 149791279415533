import React from "react";
import { Box, Text, IconButton, HStack } from "@chakra-ui/react";
import { IoSettingsSharp } from "react-icons/io5";

const Header = ({ user, onClick }) => {
  return (
    <Box w="100%" h={7} pr={6} bg="baseDark">
      <HStack w="100%" justify="flex-end" align="center" spacing={6}>
        <Text fontSize="sm" color="baseLight">
          {user}
        </Text>
        <IconButton
          variant="transparent"
          color="baseDark"
          size="xs"
          aria-label="Settings"
          onClick={onClick}
          icon={<IoSettingsSharp size={18} color="white" />}
        />
      </HStack>
    </Box>
  );
};

export default Header;
