import React from "react";
import { Flex, Text, HStack, Image, Box } from "@chakra-ui/react";

const DiplomaCard = ({
  title,
  icon,
  downloadIcon,
  downloadDiploma,
  userId,
  learningpathId,
  disabled,
}) => {
  const onDownloadDiploma = async (event) => {
    event.preventDefault();
    let response = await downloadDiploma({
      userId,
      learningpathId,
    });
    // creating a hidden <a> tag
    var a = document.createElement("a");

    // creating a reference to the file
    var url = window.URL.createObjectURL(response.data);

    // setting anchor tag's href attribute to the blob's URL
    a.href = url;

    // setting anchor tag's download attribute to the filename
    a.download = "diploma.pdf";
    document.body.append(a);

    a.click();

    // after clicking it, remove it from the DOM
    a.remove();
    // release an existing object URL which was previously
    // created by calling URL.createObjectURL()
    // once we have finished using an object URL, let the
    // browser know not to keep the reference to the file any longer.
    window.URL.revokeObjectURL(url);
  };

  return (
    <Flex
      justify="space-between"
      align="center"
      bg="primary"
      opacity={disabled ? "50%" : "100%"}
      cursor="pointer"
      onClick={onDownloadDiploma}
      pointerEvents={disabled ? "none" : "auto"}
    >
      <HStack spacing="25px">
        <Box p={3} bg="baseDark">
          <Image
            src={
              icon ? "gfx/modules/" + icon + ".png" : "modules/checkmark.png"
            }
            alt="module icon"
            objectFit="contain"
            w="35px"
          />
        </Box>
        <Text
          pt={1.5}
          pb={1.5}
          color="baseDark"
          fontWeight="normal"
          textTransform="uppercase"
        >
          {title}
        </Text>
      </HStack>
      <Box p={3}>
        <Image
          src={"modules/" + downloadIcon + ".png"}
          alt="Start module"
          objectFit="contain"
          w="35px"
        />
      </Box>
    </Flex>
  );
};

export default DiplomaCard;
