import React, { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Text,
  Box,
  VStack,
  Flex,
  Input,
  Select,
} from "@chakra-ui/react";

const options = [
  "Ansatte treningssenter",
  "Medlemmer treningssenter",
  "Åpent arrangement",
];

const RequestForm = () => {
  const aRef = useRef();
  const {
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      center: "",
      mail: "",
      phone: "",
      activity: "",
      date: "",
      targetGroup: "",
    },
  });

  const onSubmit = (d, e) => {
    const form = e?.target;
    console.log("data: ", d, " form: ", form);
    const body = `${d.center}%0D%0AØnsket aktvitet: ${d.activity}%0D%0AØnsket dato: ${d.date}%0D%0AMålgruppe: ${d.targetGroup}%0D%0A%0D%0AKontaktinformasjon%0D%0Ae-post: ${d.mail}%0D%0Atelefon: ${d.phone}`;
    window.open(
      `mailto:post@antidoping.no?subject=Forespørsel - Rent Senter&body=${body}`
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing="15px" width="100%" align="flex-start" pt={4}>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify={{ sm: "flex-start", md: "space-between" }}
            align="center"
            width={{ sm: "100%", md: "60%" }}
          >
            <Text
              width={{ sm: "100%", md: "35%" }}
              pb={{ sm: "10px", md: "0px" }}
            >
              Senter:{" "}
            </Text>
            <Controller
              control={control}
              name="center"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  variant="none"
                  borderRadius={0}
                  size="sm"
                  width={{ sm: "100%", md: "65%" }}
                />
              )}
            />
          </Flex>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify={{ sm: "flex-start", md: "space-between" }}
            align="center"
            width={{ sm: "100%", md: "60%" }}
          >
            <Text
              width={{ sm: "100%", md: "35%" }}
              pb={{ sm: "10px", md: "0px" }}
            >
              E-post:{" "}
            </Text>
            <Controller
              control={control}
              name="mail"
              type="text"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  variant="none"
                  borderRadius={0}
                  size="sm"
                  width={{ sm: "100%", md: "65%" }}
                />
              )}
            />
          </Flex>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify={{ sm: "flex-start", md: "space-between" }}
            align="center"
            width={{ sm: "100%", md: "60%" }}
          >
            <Text
              width={{ sm: "100%", md: "35%" }}
              pb={{ sm: "10px", md: "0px" }}
            >
              Telefon:{" "}
            </Text>
            <Controller
              control={control}
              name="phone"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  variant="none"
                  type="number"
                  borderRadius={0}
                  size="sm"
                  width={{ sm: "100%", md: "65%" }}
                />
              )}
            />
          </Flex>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify={{ sm: "flex-start", md: "space-between" }}
            align="center"
            width={{ sm: "100%", md: "60%" }}
          >
            <Text
              width={{ sm: "100%", md: "35%" }}
              pb={{ sm: "10px", md: "0px" }}
            >
              Ønsket aktivitet:{" "}
            </Text>
            <Controller
              control={control}
              name="activity"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  variant="none"
                  type="text"
                  borderRadius={0}
                  size="sm"
                  width={{ sm: "100%", md: "65%" }}
                />
              )}
            />
          </Flex>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify={{ sm: "flex-start", md: "space-between" }}
            align="center"
            width={{ sm: "100%", md: "60%" }}
          >
            <Text
              width={{ sm: "100%", md: "35%" }}
              pb={{ sm: "10px", md: "0px" }}
            >
              Ønsket dato:{" "}
            </Text>
            <Controller
              control={control}
              name="date"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  variant="none"
                  borderRadius={0}
                  size="sm"
                  width={{ sm: "100%", md: "65%" }}
                />
              )}
            />
          </Flex>
          <Flex
            direction={{ sm: "column", md: "row" }}
            justify={{ sm: "flex-start", md: "space-between" }}
            align="center"
            width={{ sm: "100%", md: "60%" }}
          >
            <Text
              width={{ sm: "100%", md: "35%" }}
              pb={{ sm: "10px", md: "0px" }}
            >
              Målgruppe:{" "}
            </Text>
            <Controller
              control={control}
              name="targetGroup"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  variant="none"
                  borderRadius={0}
                  size="sm"
                  width={{ sm: "100%", md: "65%" }}
                >
                  {options.map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </Select>
              )}
            />
          </Flex>
          <Flex
            width={{ sm: "100%", md: "60%" }}
            justify="flex-end"
            align="flex-end"
          >
            <Button
              type="submit"
              size="sm"
              bg="primary"
              color="baseLight"
              borderRadius={0}
              _hover={{ cursor: "pointer", bg: "baseDark" }}
              width={{ sm: "100%", md: "65%" }}
            >
              SEND FORESPØRSEL
            </Button>
          </Flex>
        </VStack>
      </form>
    </>
  );
};

export default RequestForm;
