export const colors = {
  baseLight: "#fff", // brightest color in project
  baseDark: "#000", // darkest color in project
  primary: "#0af", // main color (very blue)
  primaryDarker: "#312941",
  buttonBackground: "#0af", // very blue
  buttonText: "#fff", // white
  buttonHover: "#000", // black
  buttonActive: "#000", // black
  link: "#0af", // blue
  linkHover: "#000", // dark blue
  errorMessage: "#a94442",
};
