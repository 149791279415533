import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

const headers = ["Varighet", "Aktivitet", "Innhold", "Pris kroner"];
const rows = [
  {
    duration: "45 min",
    activity: "Rent Senter - Foredrag",
    content: [
      "Implementering av antidopingprogram",
      "Hva forventes av ulike roller på senteret",
      "Rutiner, fra mistanke til dopingkontroll",
      "Ansvarsfordeling",
    ],
    price: "2500 kr",
  },
  {
    duration: "55 min",
    activity: "Doper seg til perfekt kropp - Foredrag",
    content: [
      "Hva er doping?",
      "Tegn og symptomer",
      "Omfang og brukergrupper",
      "Brukermotivasjon",
      "Dopingpreparater",
      "Bivirkninger",
    ],
    price: "3500 kr",
  },
  {
    duration: "55 min",
    activity: "Personlige Historier - Foredrag",
    content:
      "Historier fra personer som direkte har blitt berørt av dopingproblematikken.",
    price: "3500 kr",
  },
  {
    duration: "3 timer",
    activity: "Rent Senter - Stand",
    content:
      "Sett fokus på antidopingarbeidet med stand fra Antidoping Norge på ditt senter. Informasjonsmateriell til medlemmer, konkurranser og antidoping effekter.",
    price: "3500 kr",
  },
];

const CustomTable = () => {
  return (
    <Table variant="unstyled">
      <Thead>
        <Tr>
          {headers.map((title, index) => (
            <Th
              key={`table-head-${index}`}
              bg="primary"
              color="baseLight"
              border="1px"
              borderLeft={index === 0 ? "0" : "1"}
              borderRight={index + 1 === headers.length ? "0" : "1"}
              borderColor="baseLight"
            >
              {title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((row, index) => (
          <Tr key={`table-row-${index}`}>
            <Td border="1px solid lightGray" borderLeft={0}>
              {row.duration}
            </Td>
            <Td border="1px solid lightGray">{row.activity}</Td>
            <Td border="1px solid lightGray">
              {Array.isArray(row.content) ? (
                <UnorderedList>
                  {row.content.map((list, index) => (
                    <ListItem key={`row-content-list-${index}`}>
                      {list}
                    </ListItem>
                  ))}
                </UnorderedList>
              ) : (
                row.content
              )}
            </Td>
            <Td border="1px solid lightGray" borderRight={0}>
              {row.price}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default CustomTable;
