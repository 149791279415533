import { Box, Image } from "@chakra-ui/react";

const FooterLogo = ({ logo, caption }) => {
  return (
    <Box pt={12} width="100%">
      <Image margin="0 auto" boxSize="100px" src={logo} alt={caption} />
    </Box>
  );
};

export default FooterLogo;
